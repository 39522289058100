.b-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
}

.specialization>ul>li {
    margin:  10px;
    
}
