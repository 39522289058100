body {
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
   
  }
.App {
    box-sizing: border-box;
    margin:0 auto;
    padding: 0 85px;
    min-width: 320px;
    max-width: 1070px;
    color: #2D3748;
    background-color: #FEFEFE;
    font-family: 'Roboto Slab', sans-serif;
    
    .Wrapper {
      // border: 1px solid #2D3748;
      padding: 5px;
      overflow: hidden;
    }
    

section {
  margin-bottom: 45px;
  
  overflow: hidden;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
} 
ol {
  padding-left: 15px;
} 

h1, h2, h3, h4, h5, h6 {
  color: #454444;
  margin: 0;
}
p, li {
  color:  #718096;
  font: 14px 'Scada Regular';
  margin: 0;
}

}

  @media screen and (max-width: 1070px){ 
    .App {
      width: 100%;

      
    }
  }

  @media screen and (max-width: 1024px) {
    .App {
      padding: 0 10px;
    }
  }

  @media screen and (max-width: 860px) {
    .content-wrapper>.mainscreen {
      padding: 24px 5px;
      .mainscreen-item {
      width: 340px;
      
      h1 {
        font-size: 38px;
      }

      h3 {
        font-size: 24px;
      }

    }
    }
    
    
  }

  @media screen and (max-width: 760px) {

    

    .Wrapper>.content-wrapper {
    margin-bottom: 20px;
    .b-grid {
      grid-template-columns: repeat(1, auto);
    }
    
    .content-card {
      width: auto;
      height: 100%;
      padding: 0;
    }
    } 

    .content-wrapper>.mainscreen {
      background: none;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0;

      .img_container{
        max-width: 320px;
      }
      .mainscreen-item {
      
      align-items: flex-start;
      padding: 15px;

      h1 {
        font-size: 28px;
      }

      h3 {
        font-size: 16px;
      }

      p {
        // text-align: center;
        font-size: 14px;
      }
    }
    }
    
    .row {
        flex-direction: column;
    }  
  }

  @media screen and (min-width: 761px) {
    .mainscreen {
      min-height: 530px;

      .img_container {
          display: none;
      }
    }
  }
  @media screen and (max-width: 614px) {
    .content-wrapper>.mainscreen {
      flex-direction: column;
      align-content: center;
      
      .img_container {
      max-width: none;
      }

      .mainscreen-item {
        width: auto;
        flex-direction: row;
        p {
          margin: auto;
        }
      }


    }

    



  }
    
  @media screen and (max-width: 560px) {
    .content-wrapper>.mainscreen {

      .mainscreen-item {
        flex-direction: column;
        p {
          text-align: end;

        }
      }
    }

  }

  



