header {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;
    padding: 16px 0;
    .wrapper {
        width: 100px;
        display: block;
        
    }
}