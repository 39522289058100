.proservices {


    .content-block-item {
        
        background-color: rgba($color: #F3F3EA, $alpha: 0.6);
        border: 1px solid rgba($color: #718096, $alpha: 0.1);
        
        // height: 250px;
        // padding: 20px;
          margin: auto;
        
        ul {
            
            // li:nth-child(2n+1) {
                
            //     // background: rgba(150, 146, 113, 0.05);
            //     // margin: 5px auto;
            // }
            // li:nth-child(2n) {
            //     background: rgba(150, 146, 113, 0.22);
            // }
            li {
                padding: 0 5px;        
            
        }

    }
}
}