
    .contact-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        box-sizing: border-box;
        align-content: center;   

    }
    .contact-block-item {
        width: 245px;
        display: block;
        overflow: hidden;
        margin: 10px 0;

        h3{
            margin-bottom: 5px;
            font: 14px 'Scada Regular';
            font-weight: 600;
            color: #2D3748;
        }
        p {
            margin-bottom: 0;
            margin-top: 0;

        }
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }