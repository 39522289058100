
.content-card {
   
   overflow: hidden;
   background-color: rgba($color: #F3F3EA, $alpha: 0.6);
   border: 1px solid rgba($color: #718096, $alpha: 0.1);
   width: 320px;
   height: 170px;
   padding: 20px;
   margin: auto;

   .content-card-title {
      text-align: center;
      padding: 5px 10px;
      cursor: pointer;
      font: 14px 'Roboto Slab Light';
      
   }  
   .content-card-item {
      display: flex;
      max-height: 300px;
      overflow: hidden;
      margin: 0px 3px 3px 3px;
      padding: 14px 5px 14px 10px;
     
      
         .content-card-description {
            overflow: hidden;
            ul>li {
               position: relative;
               margin-left: 15px;
            }
            ul>li::before {
               position: absolute;
               // top: .7em;
               left: -14px;
               content: "-";
               // margin: auto;
               // left: 0px;
            }
            
            
        
         }
        
         
      }
   
      


 }
.content-list-item {
         margin: 8px 15px;
         font: 14px 'Roboto Slab Light';
         

      }


.content-card.active
 {
   .content-card-item {
      
   }
}
  


