.IconBar {
    
    ul {
        display: flex;
        justify-content: center;
        list-style-type: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        
        li {
            margin-right: 15px;
            a {
                display: block;
                width: 20px;
                height: 20px;
                text-decoration: none;
                img {
                    height: 100%;
                }
            }
            


            
        }
        li:last-child {
            margin-right: 0;
        }
        
    }
}
