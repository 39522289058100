Nav {
    position: relative;
    z-index: 10;
    background-color: #FEFEFE ;
    
    
   
  
    .menu-icon {
        // background-color: aqua;
       
        display: flex;
        width: 20px;
        height: 20px;
        cursor: pointer;
        z-index: 10;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span::before,
        span,
        span::after {
            display: block;
            height: 2px;
            background-color: #2D3748;
            border-radius: 5px;
            transition-duration: 0.25s;
        }
       
        span::before {
            position: absolute;
            left: 0;
            content: "";
            top: -0px;
            width: 20px;
        }
        span {
            width: 16px;
        }
        span::after {
            position: absolute;
            left: 0;
            content: "";
            bottom: 0px;
            width: 20px;
            
        }
        
    }
   
    .menu-list {
        visibility: hidden;
        bottom: -100%;
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        list-style-type: none;
        font-size: 16;
        padding-left: 0;
        margin: 5px;
        border: 1px #2D3748 solid;
        border-top: none;
        outline: 5px solid #FEFEFE;
        background-color: #FEFEFE ;
        
        
        
        
        li {
            text-align: center;
            margin-left: 5px;
            margin-right: 5px;
            a {
                font: 16px "Scada Regular";
                text-decoration: none;
                color: #000000;
            }
        }

}


}

Nav.active {
    .menu-icon { 
        
    
        span::before {
            content: '';
            top: 9px;
            width: 20px;
            height: 2px;
            transform: rotate(45deg);
        }

        span {
            width: 0%;
        }
        
        span::after {
            content: '';
            top: 9px;
            width: 20px;
            height: 2px;
            transform: rotate(-45deg);
        }        
    }
    .menu-list {
        visibility: visible;
        bottom: auto;
        
    }


}
