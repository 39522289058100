.mainscreen {
    color: #a5aab1;
    display: flex;    
    flex-direction: column;
    align-items: flex-end;
    padding: 24px 15px;

    .img_container {
        overflow: hidden;
        width: 100%;
        min-width: 310px;

        img {
            display: block;
            margin: 0 auto;
            width: 100%;
        }
    }        
}

.background {
        
        background-image: url("../../img/mainscreen-background.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 115%;
        background-position-x: right;
        // background: none;
}

.mainscreen-item  {
    display: flex;
    flex-direction: column;
    width: min-content;
    
    h1 {
        // color: rgba(255, 255, 255, 0.8);
        font-family: 'Roboto Slab Light';
        letter-spacing: 5px;
        margin-top: 0;
        font-size: 42px;
        // font-weight: 500;
        margin-bottom: 0;
    }

    h2 {
        // color: rgba(255, 255, 255, 0.8);
        font-family: 'Lobster';
        font-weight: 500;
        letter-spacing: 0.25rem;
        margin-top: 0px;
        margin-bottom: 28px;
        font-size: 28px;
        text-align: right;
    }
    
    p {
        margin-top: 20px;
        margin-bottom: 16px;
        font: 16px 'Noto Serif Italic';
        font-weight: 600;
        color: #2D3748;
    }

}

.mainscreen-item__header {
    width: min-content;
}