.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    
    .col {
        overflow: hidden;
        margin: 10px;
        min-width: 280px;
        
        
    }
}   

.item1 {
    margin: 0 auto;
    flex: 1 0 25%;   
    .background {
        
        background-image: url("../../img/img_prof.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: left;
        // background: none;
    }
}

.item2 {
    flex: 1 0 50%;
    text-align: justify;
        h3 {
            margin-bottom: 20px;
        }
}

.img_container {
    overflow: hidden;
    width: 100%;
    height: 100%;

    img {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
}